import React from 'react';
import {
  MdClearAll
} from 'react-icons/md';
import { IoMdLogOut } from 'react-icons/io';
import {
  Button,
  Nav,
  Navbar,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { OktaAuth } from 'auth'
import Typography from 'components/Typography';

const bem = bn.create('header')

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    isModalOpen: false
  };
  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  logout = () => {
    OktaAuth.signOut({
      accessToken: JSON.parse(window.sessionStorage.getItem('okta-token-storage')).accessToken.accessToken
    });
    window.location.replace(window.location.origin)
  }

  openModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    })
  }
  render() {
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    
    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} color='black'/>
          </Button>
        </Nav>
        <Nav navbar>
        </Nav>
        <Nav navbar className={bem.e('nav-right')}>
        { 
        user && (
        <Typography style={{marginTop: '10pt', paddingRight: '30pt', color:'gray', }}>Bienvenido(a): {user.given_name} {user.family_name} </Typography>
        )
        } 
          <Button outline onClick={this.logout}><IoMdLogOut color='black'/></Button>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;
