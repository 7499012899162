import React from 'react'
import { Row, Col } from 'reactstrap'
import { SteFiles } from 'components/Vendor'
import axios from 'axios'
import Page from 'components/Page'
import envConfig from '../../config'
import { NearMeOutlined } from '@mui/icons-material'
class UploadSTE extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: null,
      nombre: 'Cargando...',
      errorMessage:'Usuario no configurado: contacta al equipo de logistica de Tyson'
    }
  }
  componentDidMount(){

      const date3='2020-10-09'
      const date4='2020-10-11'
      let user = JSON.parse(window.sessionStorage.getItem('user'))
      axios.post(`${envConfig.backend.api}/fiscalDocument/carrierSTEs`, {carrierEmail: user.email,STEStartDate: date3,STEEndDate: date4  })
      .then(response =>{
          this.setState({ name: response.data.body[0].Carriers_Name })
          
      })
      setTimeout(() => {
        if(this.state.name===null||this.state.name===undefined){
          
          this.setState({nombre: this.state.errorMessage})
          
        }else{
          this.setState({nombre: this.state.name})
        }
      }, 2000); 
  }
  render () {
    const {nombre }= this.state;
    
    

    return (
     
    <Page title={nombre} breadcrumbs={[{ name: 'Visualizar solicitudes de transporte', active: true }]}>    
          <Row>
            <Col xl={1} lg={1} />
            <Col xl={12} lg={12} md={12}>
              <SteFiles />
            </Col>
            <Col xl={1} lg={1} />
        </Row>
    </Page>
    )
  }
}

export default UploadSTE