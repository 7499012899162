import React from 'react'
import filesError from '../../assets/img/filesError.png'
import filesImage from '../../assets/img/filesImage.png'
import { withTranslation } from 'react-i18next'

const PreviewText = (props) => {
  const { t, validDrop } = props
  let src
  let text

  if (validDrop) {
    src = filesImage
    text = 'Drag & Drop your files here, or well, just click an upload the file'
  } else {
    src = filesError
    text = 'You only can upload PDF and XML files'
  }
  return (
    <>
      <img src={src} alt='icon' />
      <span className='text-center'>{t(text)}</span>
    </>
  )
}

export default withTranslation()(PreviewText)
