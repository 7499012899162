import React from 'react'
import { Row, Col, Label } from 'reactstrap'
import { withTranslation } from 'react-i18next'

const InvoiceTotal = ({ subtotal, withholded, transferred, total, currency, t }) => {
  return (
    <Row className='invoice-total'>
      <Col xl={3} lg={4} md={4} sm={12} xs={12} className='totalSubtotal' >
        <Label>{t('Subtotal')}</Label>
        <h3>
          $ {subtotal.toLocaleString('en-US')}
        </h3>
      </Col>
      <Col xl={3} lg={4} md={4} sm={12} xs={12} className='totalSubtotal' >
        <Label>{t('Withholded')}</Label>
        <h3>
          $ { withholded.toLocaleString('en-US')}
        </h3>
      </Col>
      <Col xl={3} lg={4} md={4} sm={12} xs={12} className='totalSubtotal'>
        <Label>{t('Transferred')}</Label>
        <h3>
          $ { transferred.toLocaleString('en-US') }
        </h3>
      </Col>
      <Col xl={3} lg={4} md={4} sm={12} xs={12} className='totalCost'>
        <Label>{t('Total')}</Label>
        <h2>
          $ { total.toLocaleString('en-US') + ' ' + currency }
        </h2>
      </Col>
    </Row>
  )
}

export default withTranslation()(InvoiceTotal)
