import React from 'react'
import Page from 'components/Page'
import { Row, Col } from 'reactstrap'
import { UploadFiles } from 'components/Vendor'
import { withTranslation } from 'react-i18next'

class UploadInvoicePage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    const { t } = this.props
    return (
      <Page title={t('Upload fiscal documents')} breadcrumbs={[{ name: t('Upload fiscal documents'), active: true }]}>
        <Row>
          <Col xl={1} lg={1} />
          <Col xl={10} lg={10} md={12}>
            <UploadFiles />
          </Col>
          <Col xl={1} lg={1} />
        </Row>
      </Page>
    )
  }
}

export default withTranslation()(UploadInvoicePage)
