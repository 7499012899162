import React from 'react'

class CalloutWidget extends React.Component {
  render () {
    const content = (this.props.children === undefined) ? (<strong className='h4'>{this.props.description}</strong>) : (this.props.children)
    return (
      <div className={`callout callout-${this.props.type}`}>
        <small className='text-muted'>{this.props.title}</small>
        <br />{content}
      </div>
    )
  }
}

export default CalloutWidget
