const spanish = {
  'Expense': 'Gasto',
  'Income': 'Ingreso',
  'Profit / loss': 'Ganancia / Perdida',
  'This month': 'Este mes',
  'Percent of total income': 'Porciento del ingreso total',
  'Percent of total expense': 'Porciento del gasto total',
  'Percent of total profit / lost': 'Porciento de la ganancia / perdida total',
  'Invoices in the repository': 'Facturas en el repositorio',
  'Actual year income': 'Ingreso del año actual',
  'Budget year income': 'Budget del año actual',
  'Pending for authorization': 'Pendientes de autorizacion',
  'Total of invoices to be verified': 'Facturas pendientes de ser comprobadas',
  'Expenditure verified': 'Gasto comprobado',
  'Expenditure pending to verify': 'Gasto pendiente de comprobar',
  'Get fiscal documents':'Ver archivos fiscales'
}

const PagesTranslation = {
  es: spanish
  // gr: german,
  // pg: portuguese
}

export default PagesTranslation
