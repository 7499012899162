const prodSecrets = {
  env: 'prod',
  backend: {
    api: 'https://8f6a8rstz4.execute-api.us-east-1.amazonaws.com/prod',
    lambda: 'https://8f6a8rstz4.execute-api.us-east-1.amazonaws.com/prod'
  },
  okta: {
    clientId: '0oa4l2e3ty1n78yQg357',
    //url: 'https://login.tyson.com/',
    url: 'https://tyson.okta.com/',
    //issuer: 'https://login.tyson.com/oauth2/default',
    issuer: 'https://tyson.okta.com/oauth2/default',
    redirectUri: 'https://proveedores.tyson.com/'
  }
}

export default prodSecrets
