import React from 'react'
import { createRoot } from "react-dom/client";
import App from './App'
import { loginWithRedirect, OktaAuth, scopes } from 'auth'

let Auth = true 

if (Auth) {
  OktaAuth.session.exists().then(authStatus => {
    if (authStatus) {
      window.history.replaceState(null, null, window.location.pathname);
      OktaAuth.token
        .getWithoutPrompt({
          scopes: scopes,
          sessionToken: OktaAuth.session.get()
        })
        .then(response => {
          OktaAuth.tokenManager.clear()
          OktaAuth.tokenManager.add('accessToken', response[0])
          OktaAuth.tokenManager.add('idToken', response[1])
          OktaAuth.token
            .getUserInfo(
              JSON.parse(window.sessionStorage.getItem('okta-token-storage'))
                .accessToken
            )
            .then(user => {
              window.sessionStorage.setItem('user', JSON.stringify(user))
              const root = createRoot(document.getElementById("root"));
              root.render(<App />)
            })
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      loginWithRedirect()
    }
  })
} else {
  //let user = { fiscaldocgroups: ['Fiscal Documents Repo Expense Approver Dev'] }
  //let user = { fiscaldocgroups: ['Fiscal Documents Repo Financial Admin Dev'] }
  /* let carrier = { fiscaldocgroups: ['External Carrier'] } */
  
  let user = { fiscaldocgroups: ['Fiscal Documents Repo External Carrier', 'Tyson'], email: 'victor.canas@tyson.com', email_verified: true, given_name: 'VICTOR DANIEL', family_name: 'CANAS MACIAS' }
  window.sessionStorage.setItem('user', JSON.stringify(user))
  window.sessionStorage.setItem('okta-token-storage', JSON.stringify({ accessToken: { accessToken: 'test' } }))
  const root = createRoot(document.getElementById("root"));
  root.render(<App />)
  console.log(user);
}
