import React from 'react'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert
} from 'reactstrap'
import Select from 'react-select'
import axios from 'axios'
import { CalloutWidget } from 'components/Widget'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FaBan, FaUpload } from 'react-icons/fa'
import envConfig from 'config'
import { FilesStatus, PreviewText } from '.'
import { withTranslation } from 'react-i18next'

class UploadFiles extends React.Component {
  constructor (props) {
    super(props)
    this.handleChangeStatus = this.handleChangeStatus.bind(this)
    this.state = this.getInitState()
  }
  /**
   * Gets the base state of the component
   */
  getInitState () {
    return {
      pdfQuantity: 0,
      xmlQuantity: 0,
      successXml: 0,
      successPdf: 0,
      failedXml: 0,
      filesResponse: [],
      capturePo: false,
      contracts: [],
      purchaseOrders: [],
      na:[],
      files: []
    }
  }
  /**
  * Executes the purchase order and the contract functions once
  * the páge has been mounted
  */
  componentDidMount = () => {
    this.getContracts()
    this.getPurchaseOrders()
  }
  /**
   * Gets all the purchase order belonging to the logged user
   * using his emails as current index for seach
   */
  getPurchaseOrders = () => {
    const { t } = this.props
    const MySwal = withReactContent(Swal)
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    let data = { user: user.email, 'rfc': ',' }

    axios.post(`${envConfig.backend.api}/fiscalDocument/purchaseOrders`, JSON.stringify(data), { timeout: 500000 })
    .then((response) => {
      this.setState({
        purchaseOrders: response.data.body
      })
      // contracts = JSON.parse(response.data.body[0].Result)
      // this.setState({ contracts: contracts })
    })
    .catch((err) => {
      MySwal.fire({
        type: 'error',
        title: 'Oops...',
        text: `${t('Sorry')}, ${t('An error has ocurred while proccesing the files')} [${err.message}] `,
        footer: `<a href="mailto:ITMEXICOSUPPORT@tyson.com?Subject=No%20pude%20subir%20mi%20factura%20al%20portal%20de%20tyson" >${t('Need help? Send us an e-mail')}</a>`
      })
    })
  }
  /**
   * Gets all the contracts belonging to the logged user
   * using his emails as current index for search
   */
  getContracts = () => {
    let contracts
    const { t } = this.props
    const MySwal = withReactContent(Swal)
    let user = JSON.parse(window.sessionStorage.getItem('user'))
    let data = { user: user.email, 'rfc': ',' }

    let env = (process.env.REACT_APP_ENV.toLowerCase() === 'qa') ? ('preprod') : (process.env.REACT_APP_ENV.toLowerCase())

    axios.post(`${envConfig.backend.api}/fiscalDocument/contracts`, JSON.stringify(data), { timeout: 500000 })
    .then((response) => {
      console.log(response.data.body[0].Result)
      if (response.data.body[0].Result === 'No Contract was found') {
        this.setState({ contracts: [] })
      } else {
        contracts = JSON.parse(response.data.body[0].Result)
        this.setState({ contracts: contracts })
      }
    })
    .catch((err) => {
      MySwal.fire({
        type: 'error',
        title: 'Oops...',
        text: `${t('Sorry')}, ${t('An error has ocurred while proccesing the files')} [${err.message}] `,
        footer: `<a href="mailto:ITMEXICOSUPPORT@tyson.com?Subject=No%20pude%20subir%20mi%20factura%20al%20portal%20de%20tyson" >${t('Need help? Send us an e-mail')}</a>`
      })
    })
  }
  /**
   * Sends trought axios the uploaded xml to start a validation with the webservice of SAT
   * @param {*} files
   */
  uploadFiles = (files) => {
    const { t } = this.props

    const MySwal = withReactContent(Swal)
    const data = new FormData()

    files.map((file, indx) => {
      data.append(`xml-${indx}`, file.file)
      return true
    })

    let pdf = 0
    let xml = 0
    let fileExt = ''
    axios.post(`${envConfig.backend.api}/fiscalDocument/upload`, data, { timeout: 500000 })
      .then((response) => {
    response.data.map((file, indx) => {
      fileExt = file.filename.split('.').pop()
      switch (fileExt) {
        case 'pdf':
          pdf = pdf + 1
          break
        case 'xml':
          xml = xml + 1
          break
        default:
          console.log('No es pdf ni xml')
          break
      }
      return true
    })
    files.forEach(f => f.remove())
    this.setState({ successXml: xml, successPdf: pdf, filesResponse: response.data, capturePo: xml >= 1 })
    })
    .catch((err) => {
      MySwal.fire({
        type: 'error',
        title: 'Oops...',
        text: `${t('Sorry')}, ${t('An error has ocurred while proccesing the files')} [${err.message}] `,
        footer: `<a href="mailto:ITMEXICOSUPPORT@tyson.com?Subject=No%20pude%20subir%20mi%20factura%20al%20portal%20de%20tyson" >${t('Need help? Send us an e-mail')}</a>`
      })
    })
  }
  /**
   * Submits all the files dragged to the dropzone
   * @param {*} files
   * @param {*} allFiles
   */
  handleSubmit = (files) => {
    this.uploadFiles(files)
  }
  /**
   * Handle the status when a new file is dragged or removed from the dropzone
   * @param {*} param0
   * @param {*} status
   */
  handleChangeStatus ({ meta }, status) {
    let { pdfQuantity, xmlQuantity } = this.state
    let pdfAdded = (meta.type === 'application/pdf' && status === 'done')
    let pdfRemoved = (meta.type === 'application/pdf' && status === 'removed')
    let xmlAdded = (meta.type === 'text/xml' && status === 'done')
    let xmlRemoved = (meta.type === 'text/xml' && status === 'removed')
    switch (true) {
      case xmlAdded:
        xmlQuantity = xmlQuantity + 1
        break
      case pdfAdded:
        pdfQuantity = pdfQuantity + 1
        break
      case xmlRemoved:
        xmlQuantity = xmlQuantity - 1
        break
      case pdfRemoved:
        pdfQuantity = pdfQuantity - 1
        break
      default:
        console.log('Aun se sigue cargando el archivo')
        break
    }
    this.setState({
      xmlQuantity: xmlQuantity,
      pdfQuantity: pdfQuantity,
      loadingTitle: meta.name
    })
  }
  /**
   * Handles the on change event of the radio button, it allows to add the data
   * to the correct propierty ob the object depending of the selected radio button in
   * the match invoice(s) modal
   * 
   * @param {*} selectedOption object that has the value and the label
   * @param {*} index number of position in files array of the invoice to match
   */
  handleOnChange (selectedOption, index) {
    let { filesResponse } = this.state

    if (filesResponse[index].relation_with === 'purchaseOrders') {
      filesResponse[index].po_num = selectedOption.value
      filesResponse[index].contract_num = 0
    } else if (filesResponse[index].relation_with === 'contracts') {
      filesResponse[index].contract_num = selectedOption.value
      filesResponse[index].po_num = 0
    } else {
      filesResponse[index].contract_num = 0
      filesResponse[index].po_num = 0
    }

    this.setState({
      [`selectedOption-${index}`]: selectedOption,
      filesResponse: filesResponse
    })
  }
  /**
   * Gets the purchase order data or the contract data depending of the 
   * selected radio button
   * 
   * @param {HTMLElement} e element of the selected option
   * @param {Int} index number of position in files array of the invoice to match
   */
  getOptions (e, index) {
    let { filesResponse } = this.state
    filesResponse[index].relation_with = e.target.value
    this.setState({
      [`selectedOption-${index}`]: 0,
      [`selectOptions-${index}`]: this.state[e.target.value],
      filesResponse: filesResponse
    })
  }
  /**
   * Once the user selected a purchase order or a contract, this functions
   * uses the UUID as an index to search and update the po_num or the contract
   * in the database
   * 
   */
  updateInvoices = () => {
    let { filesResponse } = this.state
    const { t } = this.props
    const MySwal = withReactContent(Swal)

    axios.post(`${envConfig.backend.api}/fiscalDocument/update`, JSON.stringify(filesResponse), { timeout: 500000 })
      .then((response) => {
          MySwal.fire(
            t('Success') + '!',
            t('Your files had been loaded and matched succesfully to the system'),
            'success'
          )
          this.setState(this.getInitState())
      })
    .catch((err) => {
      MySwal.fire({
        type: 'error',
        title: 'Oops...',
        text: `${t('Sorry')}, ${t('An error has ocurred while proccesing the files')} [${err.message}] `,
        footer: `<a href="mailto:ITMEXICOSUPPORT@tyson.com?Subject=No%20pude%20subir%20mi%20factura%20al%20portal%20de%20tyson" >${t('Need help? Send us an e-mail')}</a>`
      })
    })
  }
  /**
   * Renders the component elements
   * 
   */
  render () {
    const { pdfQuantity, xmlQuantity, failedXml, filesResponse, capturePo, successXml, successPdf } = this.state
    const { t } = this.props

    return (
      <>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardHeader className='infoForm'>
                {t('Vendor invoice incorporation')}
              </CardHeader>
              <CardBody>
              <Row>
                  <Col xl={1} lg={1} md={6} sm={3} xs={12}>
                  </Col>
                  <Col xl={2} lg={2} md={6} sm={3} xs={12}>
                    <CalloutWidget
                      type='info'
                      title={'PDF ' + t('ready for upload')}
                      description={pdfQuantity + ' ' + t('Total PDF')} />
                  </Col>
                  <Col xl={2} lg={2} md={6} sm={3} xs={12} style={{padding:'1px'}}>
                    <CalloutWidget
                      type='success'
                      title={'PDF ' + t('uploaded successfully')}
                      description={successPdf + ' ' + t('Total PDF')} />
                  </Col>
                  <Col xl={2} lg={2} md={6} sm={3} xs={12}>
                    <CalloutWidget
                      type='info'
                      title={'XML ' + t('ready for upload')}
                      description={xmlQuantity + ' ' + t('Total XML')} />
                  </Col>
                  <Col xl={2} lg={2} md={6} sm={3} xs={12} style={{padding:'1px'}}>
                    <CalloutWidget
                      type='success'
                      title={'XML ' + t('uploaded successfully')}
                      description={successXml + ' ' + t('Total XML')} />
                  </Col>
                  <Col xl={2} lg={2} md={6} sm={3} xs={12}>
                    <CalloutWidget
                      type='danger'
                      title={'XML ' + t('uploaded in error')}
                      description={failedXml + ' ' + t('Total XML')}
                    />
                  </Col>
                </Row>
              </CardBody>
              <Dropzone
                onSubmit={this.handleSubmit}
                onChangeStatus={this.handleChangeStatus}
                accept='application/pdf, text/xml'
                submitButtonContent={t('Match files')}
                inputContent={(files, extra) => (extra.reject ? <PreviewText validDrop={false} /> : <PreviewText validDrop />)}
                submitButtonDisabled={files => xmlQuantity === 0}
                styles={{
                  dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                  inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {})
                }}
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <FilesStatus files={filesResponse} />
          </Col>
        </Row>
        <Modal isOpen={capturePo} size='xl'>
          <ModalHeader className='infoForm' toggle={() => this.setState({ capturePo: false })} >Match your invoice (s)</ModalHeader>
          <ModalBody>
            <Alert color='warning'>
              <h4 className='alert-heading'>Almost done!</h4>
              <p>
                You are a step close to send your CFDI's to Tyson Mexico Trading Company, before send them,
                you need to match your invoice with the purchase order or contract number related that belong
                to this invoice.
              </p>
              <hr />
              <p className='mb-0'>
                If your invoice does not have a purchase order or contrat to match, you can leave the fields in blank.
              </p>
            </Alert>
            <Form>
              <Row form>
                <Col md={4}>
                  <Label for='FileName'><strong>File name</strong></Label><br />
                </Col>
                <Col md={4}>
                  <Label for='CheckPoCo'><strong>It's Purchase order or Contract?</strong></Label> <br />
                </Col>
                <Col md={4}>
                  <Label for='SelectFolio'><strong>Select your folio</strong></Label><br />
                </Col>
              </Row>
            </Form>
            <hr />
            <Form>
              {filesResponse.map((file , index) => {
                return (file.filename.slice(-3) === 'xml' && file.statusCode === 1) ? (
                  <Row form key={index}>
                    <Col md={4}>
                      <FormGroup>
                        <Label>{file.filename}</Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup check inline>
                        <Label check>
                          <Input type='radio' value='purchaseOrders' name={`purchaseOrder-${index}`} onChange={(e) => this.getOptions(e, index)} /> Purchase order
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check>
                          <Input type='radio' value='contracts' name={`purchaseOrder-${index}`} onChange={(e) => this.getOptions(e, index)} /> Contract
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Select
                          name={`select-${index}`}
                          value={this.state[`selectedOption-${index}`]}
                          onChange={(selectedOption) => this.handleOnChange(selectedOption, index)}
                          options={this.state[`selectOptions-${index}`]}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : (null)
              })}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color='danger' outline onClick={() => this.setState({ capturePo: false })} > Cancel <FaBan/> </Button>{' '}
            <Button color='success' onClick={this.updateInvoices}> Match invoices <FaUpload/></Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

export default withTranslation()(UploadFiles)
