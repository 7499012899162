//import devSecrets from './dev'
import qaSecrets from './qa'
import prodSecrets from './prod'
import testSecrets from './test'

let hostname = window.location.hostname

let envConfig = {}
switch (hostname) {
  /*case 'fiscal-doc-s3-webhost-preprod.tyson.com':
    envConfig = devSecrets
    break*/
  case 'fiscal-doc-s3-webhost-preprod.tyson.com':
    envConfig = qaSecrets;
    break
  case 'proveedores.tyson.com':
    envConfig = prodSecrets
    break
  default:
    envConfig = testSecrets
    break
}

export default envConfig
