const testSecrets = {
  env: 'test',
  backend: {
    api: 'https://kt3vwhxga2.execute-api.us-east-1.amazonaws.com/dev',
    lambda: 'https://kt3vwhxga2.execute-api.us-east-1.amazonaws.com/dev'
  },
  okta: {
    clientId: '0oa4l2e3ty1n78yQg357',
    url: 'https://dev-669314.okta.com/',
    redirectUri: 'http://localhost:3000/'
  }
}

export default testSecrets
