import React from 'react'
import { Card, CardBody } from 'reactstrap'

class InfoWidget extends React.Component {
  render () {
    let classes = `${this.props.type}-detail-panel`
    return (
      <Card>
        <CardBody className={classes}>
          {this.props.children}
        </CardBody>
      </Card>
    )
  }
}

export default InfoWidget
