const qaSecrets = {
  env: 'qa',
  backend: {
    api: 'https://kt3vwhxga2.execute-api.us-east-1.amazonaws.com/dev',
    lambda: 'https://kt3vwhxga2.execute-api.us-east-1.amazonaws.com/dev'
  },
  okta: {
    clientId: '0oaqgzdtwzTdLMBah0h7',
    url: 'https://tyson.oktapreview.com/',
    issuer: 'https://tyson.oktapreview.com/oauth2/default',
    redirectUri: 'https://fiscal-doc-s3-webhost-preprod.tyson.com/'
  }
}

export default qaSecrets
