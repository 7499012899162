const spanish = {
  'Upload invoices': 'Subir factura',
  'Search invoices': 'Buscar facturas',
  'Masive files upload results': 'Resultados sobre carga masiva de documentos',
  'Status': 'Estado',
  'File name': 'Nombre del archivo',
  'Validator response': 'Resulto del validador',
  'You only can upload PDF and XML files': 'Solo puedes subir archivos PDF y XML',
  'Serial': 'Serie',
  'Number': 'Folio',
  'UUID': 'UUID',
  'Payment form': 'Forma de pago',
  'Currency': 'Moneda',
  'Payment method': 'Metodo de pago',
  'Total': 'Total',
  'Invoice date': 'Fecha de factura',
  'Upload date': 'Fecha de carga en el sistema',
  'Vendor invoice incorporation': 'Incorporacion de facturas de proveedor',
  'in total': 'en total',
  'ready to upload': 'listos para subir',
  'correctly verified': 'correctamente validados',
  'failed': 'fallidos',
  'Success': 'Exito',
  'Your files had been loaded successfully to the system': 'Tus archivos han sido cargados correctamente en el sistema',
  'Sorry': 'Lo sentimos',
  'An error has ocurred while proccesing the files': 'Un error a ocurrido mientras procesabamos los archivos',
  'Need help? Send us an e-mail': 'Necesitas ayuda? Envianos un e-mail',
  'Drag & Drop your files here, or well, just click an upload the file': 'Arrastra y suelta tus archivos aqui, o bien, solo haz click y sube un archivo',
  'Upload fiscal documents': 'Carga de archivos fiscales',
  'Invoice monitor': 'Monitor de facturas',
  'Expenses': 'Gastos'
}

const VendorsTranslation = {
  es: spanish
  // gr: german,
  // pg: portuguese
}

export default VendorsTranslation
