import * as React from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { DataGrid } from '@mui/x-data-grid';
import { GridToolbar } from '@mui/x-data-grid';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import axios from 'axios';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { FormControlLabel, 
         Grid, 
         Box, 
         FormControl,
         Button, 
         IconButton, 
         CircularProgress, 
         Paper,
         Typography,
         TextField,
         Tooltip
        } from '@mui/material'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import Modal from '@mui/material/Modal';
import Swal from 'sweetalert2';
//import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import DateFnsUtils from '@date-io/date-fns';
import { Stack } from '@mui/material';
import {
  Card,
  CardHeader,
  CardBody,
  } from 'reactstrap'
import { GridCloseIcon } from '@mui/x-data-grid';
import envConfig from '../../config'
          /* --------api--------- */
/* const URI='http://localhost:8000/api/fiscal/carrierSTE' */
const URI= `${envConfig.backend.api}/fiscalDocument/carrierSTEs`
const URIstatus=`${envConfig.backend.api}/fiscalDocument/updateSTE`
/*--------- modal----------- */

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '0.5px solid #000',
  boxShadow: 24,
  p: 4,
};
let  IdRow= 0

/* ---------------------EXPORT-------------------- */
function SteFiles() {
 
/* Columns */


  const columns = [
    { field: 'id', headerName: 'Folio STE', width: 100, hideable: false},
    { field: 'Date', headerName: 'Fecha', width: 200 },
    { field: "Origins_Abv_Name", headerName: 'Id Ori', width: 80 },
    { field: "Origins_Name", headerName: 'Origen', width: 250 },
    { field: "Destinations_Abv_Name", headerName: 'Id Des', width: 80 },
    { field: "Destinations_Name", headerName: 'Destino', width: 400 },
    { field: 'Merch_Type', headerName: 'Tipo Mercancía', width: 130 },
    {   field: 'Status'
      , headerName: 'Estado'
      , width: 200
     
    },
  
    {
      field: "actions",
      headerName: "Acción",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
   
        if(params.row.Status==="LIBERADA"){
          
           return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Edit index={params.row.id} /> 
          </div>
           );
        }
        if(params.row.Status==="RECHAZADA LOGISTICA"){
          
          return (
         <div
           className="d-flex justify-content-between align-items-center"
           style={{ cursor: "pointer" }}
         >
           <Edit index={params.row.id} /> 
         </div>
          );
       }
       if(params.row.Status==="RECHAZADA PARA PAGO"){
          
        return (
       <div
         className="d-flex justify-content-between align-items-center"
         style={{ cursor: "pointer" }}
       >
         <Edit index={params.row.id} /> 
       </div>
        );
     }
        if(params.row.Status==="CANCELADA TRAF"){
          return (
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ cursor: "pointer" }}
            >
              <GridCloseIcon color="secondary"></GridCloseIcon>
            </div>
             );
        }
        else{
        return(
          <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
       <File index={params.row.id} />   
        </div>
        )
         
        }
       
      },
    
    },
  ];
  
  
 /* btn ver archivo */
  const File = ({ index }) => {
    const handleFileClick = () => {
     Swal.fire(
      'En Revisión',
      'Esta solicitud está en revisión, gracias por esperar',
       'info'
     )
    

    };
  
    return (
      <FormControlLabel
        control={
          <IconButton
            color="secondary"
            aria-label="add an alarm"
            onClick={handleFileClick}
          >
          <FileOpenIcon />
          </IconButton>
        }
      />
    );
  };
  /* btn subir archivo */
 
  const Edit = ({ index }) => {
    const handleEditClick = () => {
      IdRow=index
      
    };
  
    return (
      <FormControlLabel
        control={
          <Tooltip title="Clic para subir archivo">
          <IconButton
            color="primary"
            aria-label="add an alarm"
            onClick={()=>{
              const funcion1= handleOpen
              const funcion2= handleEditClick
              funcion1()
              funcion2()
            }}
          >
           
           <FileUploadIcon/>  
          </IconButton></Tooltip>
        }
      />
    );
  };
 

/* OPEN MODAL */
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const  handleClose = () => setOpen(false);

/* GET CARRIER STE */
  

    /* testing email const [email]= React.useState('ROSA.MARIA@TAO.COM.MX') */
    const [date1, setDate1] = React.useState(null)
    const [date2, setDate2] = React.useState(null)
    const [ste, setSTE]= React.useState([])
    const [loading, setLoading]= React.useState(false)
    const [pageSize, setPageSize] = React.useState(10)
    
    const getStes = ()=>{
     
      let user = JSON.parse(window.sessionStorage.getItem('user'))
     
      

      if(date1 === null || date2 === null){
        Swal.fire(
          'Falta información',
          `Ambos campos deben contener información para realizar la consulta.`,
          'warning'
        ) 
        return false
      }
      setLoading(true)
       axios.post(URI, {carrierEmail: /* email */user.email,STEStartDate: date1,STEEndDate: date2  },)
      .then(response =>{

        setSTE(response.data.body)
        setLoading(false)
        const current= new Date()
        if (date1!=null && date2!=null) {
          const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
          const fecha1 = `${date1.getDate()}/${date1.getMonth()+1}/${date1.getFullYear()}`;
          const fecha2 = `${date2.getDate()}/${date2.getMonth()+1}/${date2.getFullYear()}`;
          if(ste.length<2&&fecha2===date){
            if(fecha1===date&&fecha2===date){
                Swal.fire(
              'Sin información',
              `Seleccione otro rango de fecha`,
              'warning'
            ) 
            }
            
          }
        }
        console.log(response.data.body);
        if(response.data.body.length===1){
          Swal.fire(
            'Sin información',
            `Seleccione otro rango de fecha`,
            'warning'
            )
        }
      })
      
     
     
     
    }
  

     /* MODAL UPLOAD FILES FUNCTIONS */
    
    
    
     const uploadFiles = async (files) => {

        //guardar archivo
        const data = new FormData()
        
        files.map((file, indx) => {
          data.append(`${IdRow}xml-${indx}`, file.file)
         
          
          return true
        })
        //subir archivo
  
      await  axios.post(`${envConfig.backend.api}/fiscalDocument/uploadSTEs`, data, { timeout: 500000 })
          .then((response) => {
           
            

        files.forEach(f => f.remove())
            setOpen(false)
            setTimeout(()=>{
              Swal.fire(
                'Completado',
                `El archivo con folio: ${IdRow} se subió correctamente`,
                'success'
              ).then(getStes)
            },2000)

        })
        .catch((err) => {
           setOpen(false)
            setTimeout(()=>{
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: `${'Ocurrio un error mientras se procesaban los archivos'} [${err.message}] `,
                footer: `<a href="mailto:ITMEXICOSUPPORT@tyson.com?Subject=No%20pude%20subir%20mi%20factura%20al%20portal%20de%20tyson" >${'¿Necesitas ayuda? Mandanos un e-mail'}</a>`
              })
            },2000)
         
        })
       
      }
     
     
     
      const handleChangeStatus = ({meta, file}, status) =>{
           
        //    console.log(status, meta, file);
          
            
      }
     
      const handleSubmit =  (files, allFiles) => {
        uploadFiles(files) 
        //Update estatus
        axios.post(URIstatus, {STEFolio: IdRow, STEStatus: 1})

        getStes()


      }
    /* styles */  
      const form={
        minWidth: 500,
        margin: '0.3em',
       
      }
      const button={
        minWidth: 100,
        margin: '0.5em'
      }
      
      

  return (
   <div>
    
    
      <Box
      sx={{
        height: '100%',
        width: '100%',
        
      }}
      >
        <Box
      sx={{

        width: '40%',
        
      }}
      ></Box>
      
           <Box m={4}>
          <Grid container spacing={1} >
              <FormControl style={form}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                      id='a'
                      autoOk
                      label="Desde"
                      value= {date1}
                      disableFuture
                      clearable
                      onChange={setDate1}
                      emptyLabel='Escoge una fecha'
                      minDate={'2020-06-01'}
                      format="dd/MM/yyyy"
                      renderInput={(props) => <TextField {...props} />}
                      />
                  </LocalizationProvider>
                  
               </FormControl>
               <FormControl  style={form}>
              
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker 
                      id='a2'
                      autoOk
                      label="Hasta"
                      value={date2}
                      disableFuture
                      clearable
                      onChange={setDate2}
                      emptyLabel='Escoge una fecha'
                      minDate={'2021-01-01'}
                      format="dd/MM/yyyy"
                      renderInput={(props) => <TextField {...props} />}
                      />
                   </LocalizationProvider>
              </FormControl>
             
        <Button
          variant="contained"
          color="info"
          style={button}
          endIcon={<AssessmentIcon/>}
          onClick={getStes}
        >
          Consultar 
        </Button>
        </Grid>
        </Box>
     
      {
            ste.length > 2 && ( 
          <Paper>
          <Box sx={{padding: '10pt'}}>
            <DataGrid
            rows={ste}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            components= {{Toolbar: GridToolbar}}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            sortModel={[{field: 'id', sort: 'asc'}]}
            autoHeight= {true}
            pagination
            />
          </Box>
          </Paper>
          )
      }
   
      {
          ste.length <2 &&(
            <Stack style={{padding: '20%'}} height="100%" alignItems="center" justifyContent="center">
              {
                loading ? ( <CircularProgress /> ):  <Typography variant="h5">Seleccione una fecha</Typography>
                
              }
            </Stack>
            
          )
      }
     
         
      </Box> 
      <div>
    </div>
  
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
       
         
          <Card style={style}>
          <CardHeader className='infoForm'>
                {"Incorporacion de documentación"}
              </CardHeader>
              <CardBody>
                    <Dropzone
                    onSubmit={handleSubmit}
                    onChangeStatus={handleChangeStatus}
                    accept='application/pdf, text/xml'
                    submitButtonContent={()=>{
                      return (
                        <Tooltip title="Solo puedes subir 1 pdf y 1 xml">

                          <Typography>Subir Archivos</Typography>  
                          
                        </Tooltip>
                      )
                    }}
                    inputContent={"Arrastra archivos o da clic para buscar"}
                    submitButtonDisabled={files=> files.length>2}
                  />
                  </CardBody>
          </Card>
          
        
      </Modal>
    </div>
    
    
  );
}
export default SteFiles
