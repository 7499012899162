import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { VendorsTranslation } from 'components/Vendor'
import PagesTranslation from 'pages/Vendor/PagesTranslation'

const resources = {
  es: {
    translation: Object.assign(VendorsTranslation.es, PagesTranslation.es)
  }
}

const options = {
  resources,
  lng: navigator.language.substring(0, 2),
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false // react already safes from xss
  },
  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  }
}

i18n
  .use(initReactI18next)

export { i18n, options }
