import React from 'react'

import { Navbar, Nav, NavItem } from 'reactstrap'

import SourceLink from 'components/SourceLink'

const Footer = () => {
  return (
    <Navbar>
      <Nav navbar>
        <NavItem>
          2019 Cloud, Web and Mobile theme, source on <SourceLink>Gitlab</SourceLink>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
