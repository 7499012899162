import React from 'react'
import { Card, CardBody, CardHeader, Table } from 'reactstrap'
import fileError from '../../assets/img/fileError.png'
import fileSuccess from '../../assets/img/fileSuccess.png'
import fileWarning from '../../assets/img/fileWarning.png'
import { withTranslation } from 'react-i18next'

class FilesStatus extends React.Component {
  handleStatusIcon (file) {
    let imageSrc
    switch (file.statusCode) {
      case 1:
        imageSrc = fileSuccess
        break
      case 2:
        imageSrc = fileError
        break
      default:
        imageSrc = fileWarning
        break
    }
    return <img alt='status icon' src={imageSrc} />
  }

  render () {
    const { t } = this.props
    const { files } = this.props
    return (files.length !== 0) ? (
      <Card>
        <CardHeader className='secondaryForm'>
          {t('Masive files upload results')}
        </CardHeader>
        <CardBody>
          <Table size='sm'>
            <thead>
              <tr>
                <th>{t('Status')}</th>
                <th>{t('File name')}</th>
                <th>{t('Validator response')}</th>
              </tr>
            </thead>
            <tbody>
              {
                files.map((file, indx) => {
                  return (
                    <tr key={indx}>
                      <td>{this.handleStatusIcon(file)}</td>
                      <td>{file.filename}</td>
                      <td>{file.reason}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
    ) : (<></>)
  }
}

export default withTranslation()(FilesStatus)
