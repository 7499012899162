import { MainLayout } from 'components/Layout'
import React from 'react'
// import componentQueries from 'react-component-queries'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './styles/index.scss'
import { i18n, options } from './i18n'
import routes from 'routes'


i18n.init(options)

const getBasename = () => {
  return `/${process.env.REACT_APP_PUBLIC_URL.split('/').pop()}`
}

class App extends React.Component {
  render () {
    return (
      <BrowserRouter basename={getBasename()}>
        <MainLayout breakpoint={this.props.breakpoint}>

        <Routes>
           {/* <Route 
              exact="false"
              path='/ste/index'
              element={<UploadSTE />}
            /> */}
              {
                routes.map((route, indx) => {
                  return route.component ? (
                    <Route
                      key={indx}
                      exact={`${route.exact}`}
                      path={route.path}
                      element={route.component}
                    />) : (null)
                })
              }
              
             
        </Routes>

        </MainLayout> 
      </BrowserRouter>
    )
  }
}

/* const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' }
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' }
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' }
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' }
  }

  if (width > 1200) {
    return { breakpoint: 'xl' }
  }

  return { breakpoint: 'xs' }
} */

// export default componentQueries(query)(App)
export default App
