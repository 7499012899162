import logo200Image from 'assets/img/logo/logo_200.png';
import SourceLink from 'components/SourceLink';
import React from 'react';
/* import { 
  MdDashboard 
} from 'react-icons/md'  */
import {
  FaFileInvoiceDollar,
  FaTable,

} from 'react-icons/fa'

import { NavLink } from 'react-router-dom';
import {
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { withTranslation } from 'react-i18next'
import routes from 'routes';
import { roles } from 'routes';

const sidebarBackground = {
  backfroundColor: `#FFFFFF`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};



const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  constructor(props){
   super(props);
     this.user= JSON.parse(window.sessionStorage.getItem('user'))
     this.validate= this.user.fiscaldocgroups[0]
  }
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };
  
  AllowedRoutes(){
    let navItems = [];

    
    const user = JSON.parse(window.sessionStorage.getItem('user'))
    
    
    const rolActual=[] //Arreglo con rol para asignarse a cada ruta
    
    //busqueda de roles
    const role1= user.fiscaldocgroups.map((role)=>{
     
      roles.forEach((rol)=>{
        if(rol.role===role)
        {
          rolActual.push(rol.role)
        }
      })
 
    })
    
      
    const currentRole = roles.filter(auth=>auth.role===rolActual[0]);
    

     routes.forEach((route)=>{
   if(currentRole[0]?.access.map((access)=>access.route===route.name).includes(true)){
       //push father
        
       navItems.push({name: route.name, to: route.path, exact: false, Icon: route.Icon})
       
   }
   
});
 
  return navItems
  }
 
  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    const { t } = this.props
    return (
      <aside className={bem.b()}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                height="90.67px"
                width="180px"
                src={ logo200Image }
                alt="Tyson logo"
              />
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {this.AllowedRoutes().map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  
                  exact={exact.toString()}
                > 
                      
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{t(name)}</span>
                </BSNavLink>

              </NavItem>
            ))}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default withTranslation()(Sidebar);
