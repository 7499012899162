import React  from 'react';

import UploadSTE from 'pages/Vendor/UploadSTE'
import UploadInvoice from 'pages/Vendor/UploadInvoice'
import {
  FaFileInvoiceDollar,
  FaTable,

} from 'react-icons/fa'

const routes = [
    {
      exact: "true", 
      path: '/ste/index', 
      name:'Movimientos STE',
      component: <UploadSTE />,
      Icon: FaTable
    },
    {
      exact: "true",  
      path: '/invoices/upload', 
      name: 'Upload invoices', 
      component: <UploadInvoice />,
      Icon: FaFileInvoiceDollar 
    }
 
]
export const roles=[
  {
    role: 'Fiscal Documents Repo External Carrier', 
      access:[
          {
              route: 'Movimientos STE',
          },
          {
            route: 'Upload invoices'
          }
          
        ]
  },
  {
    role: 'Fiscal Documents Repo External Carrier QA', 
      access:[
          {
              route: 'Movimientos STE',
          },
          {
            route: 'Upload invoices'
          }
          
        ]
  },
  {
    role: 'Fiscal Documents Repo External Vendor', 
      access:[
          {
              route: 'Movimientos',
          },
          {
            route: 'Upload invoices'
          }
          
        ]
  },
  {
    role: 'Fiscal Documents Repo External Vendor QA', 
      access:[
          {
              route: 'Movimientos',
          },
          {
            route: 'Upload invoices'
          }
          
        ]
  },
  
  ]

export default routes
