import OktaAuth from '@okta/okta-auth-js'
import envConfig from '../config'
/* let url = ''
url = window.location.protocol + '//' + window.location.hostname
url = window.location.port ? url + ':' + window.location.port : url */
export default new OktaAuth({
  tokenManager: {
    storage: 'sessionStorage',
    secure: true
  },
  //issuer: `${process.env.REACT_APP_OKTA_ISSUER}oauth2/default`,
  issuer: `${envConfig.okta.issuer}`,
  pkce: true,
  url: `${envConfig.okta.url}`,
  clientId: `${envConfig.okta.clientId}`,
  redirectUri: `${envConfig.okta.redirectUri}`,
})
